import React, { useState, useRef, useEffect } from 'react'

import { isMobileScreen } from '@/utils/device'
import { VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded'
import { Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'

import useStyles from './styles'

interface StreamPlayerInput {
    url: string
}

const CampaignPlayer: React.FC<StreamPlayerInput> = ({ url }) => {
    const classes = useStyles()
    const [playerVolume, setPlayerVolume] = useState(100)
    const [paused, setPaused] = useState(true)
    const [muted, setMuted] = useState(false)
    const audioRef = useRef<HTMLAudioElement | null>(null)

    const handleStreamStatus = () => {
        if (audioRef.current) {
            if (paused) {
                audioRef.current.play()
            } else {
                audioRef.current.pause()
            }
            setPaused(!paused)
        }
    }

    const handlePlayerVolume = (_: any, value: number | number[]) => {
        setPlayerVolume(value as number)
        if (audioRef.current) {
            audioRef.current.volume = (value as number) / 100
        }
        if (muted) {
            setMuted(false)
        }
    }

    const toggleMute = () => {
        if (audioRef.current) {
            audioRef.current.muted = !muted
            setMuted(!muted)
        }
    }

    useEffect(() => {
        const audioElement = audioRef.current
        if (audioElement) {
            audioElement.volume = playerVolume / 100
            audioElement.muted = muted
        }
    }, [playerVolume, muted])

    useEffect(() => {
        const audioElement = audioRef.current;
        const handleAudioEnd = () => {
            setPaused(true);
        };

        if (audioElement) {
            audioElement.addEventListener('ended', handleAudioEnd);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('ended', handleAudioEnd);
            }
        };
    }, []);

    return (
        <Grid>
            <audio
                ref={audioRef}
                preload="none"
                controls={true}
                hidden={true}
                muted={muted}
            >
                <source src={url} type="audio/aac" />
            </audio>
            <Stack className={classes.stack}>
                <IconButton
                    className={classes.iconButton}
                    color="secondary"
                    aria-label={paused ? 'play' : 'pause'}
                    onClick={handleStreamStatus}
                >
                    {paused ? <PlayArrowRounded /> : <PauseRounded />}
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    color="secondary"
                    aria-label={muted ? 'unmute' : 'mute'}
                    onClick={toggleMute}
                >
                    {playerVolume === 0 || muted
                        ? (
                            <VolumeOff color="secondary" />
                        )
                        : playerVolume > 50
                            ? (
                                <VolumeUp color="secondary" />
                            )
                            : (
                                <VolumeDown color="secondary" />
                            )}
                </IconButton>
                {!isMobileScreen && (
                    <Slider
                        className={classes.slider}
                        color="secondary"
                        aria-label="Volume"
                        value={playerVolume}
                        onChange={handlePlayerVolume}
                    />
                )}
            </Stack>
        </Grid>
    )
}

export default CampaignPlayer
