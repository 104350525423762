import { useSnackbar } from 'notistack'
import React, { ReactNode, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { AuthContext } from '@/contexts/auth'
import ApiService from '@/services/Api'
import {
    AssignmentOutlined as AssignmentIcon,
    HomeOutlined as HomeIcon,
    LiveTvOutlined as StreamingIcon,
    ExitToApp as LogoutIcon,
    Brightness7, Brightness4
} from '@mui/icons-material'
import {
    Badge,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Button,
    Switch,
    FormControlLabel
} from '@mui/material'

import useStyles from './styles'

const WhatsappIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" className="svg-inline--fa fa-whatsapp fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
        </SvgIcon>
    )
}

type MenuProps = {
    opened: boolean
    onClose: () => void
}

type MenuItem = {
    title: string,
    to: string,
    icon: ReactNode,
    isComing?: boolean
    hide?: boolean,
    beta?: boolean,
}

const Menu: React.FC<MenuProps> = (props) => {
    const { opened, onClose } = props

    const classes = useStyles()

    const { product, whatsappEnabled, user } = useContext(AuthContext)

    const location = useLocation()

    const { enqueueSnackbar } = useSnackbar()

    const isMenuItemActive = (route: string) => location.pathname.includes(route)

    const menuItems: MenuItem[] = [{
        title: 'Home',
        to: '/home',
        icon: <HomeIcon />
    }, {
        title: 'WhatsApp',
        to: 'whatsapp',
        icon: <WhatsappIcon />,
        hide: !(whatsappEnabled && user?.isBetaTester),
        beta: true
    }, {
        title: 'Programas',
        to: 'programs',
        icon: <AssignmentIcon />,
        hide: true, // !user?.isBetaTester,
        beta: true
    }, {
        title: 'Streaming',
        to: 'streaming',
        icon: <StreamingIcon />,
        isComing: true,
        hide: product !== 'metrics'
    }]

    const { setUser, setCompany, setDarkMode, darkMode } = useContext(AuthContext)

    const handleLogout = async () => {
        localStorage.removeItem('token')

        setUser(null)
        setCompany(null)
        setDarkMode(false)
    }

    const toggleDarkMode = async () => {
        setDarkMode(!darkMode)
        try {
            await ApiService.post('user/preferences', { uuid: user?.uuid, darkMode: !darkMode })
        } catch (err) {
            enqueueSnackbar((err || 'Ocorreu um erro ao mudar a preferência do tema.') as String, {
                variant: 'error'
            })
        }
    }

    return (
        <Drawer
            open={opened}
            variant="temporary"
            anchor="left"
            onClose={onClose}
            className={classes.drawer}
            sx={{
                '& .MuiDrawer-paper': { backgroundColor: '#fafafa !important' }
            }}
        >
            <List className={classes.menuList}>
                {
                    menuItems && menuItems?.map(item => (
                        !item.hide && <ListItem
                            button
                            key={item.to}
                            to={item.to}
                            onClick={onClose}
                            component={Link}
                            className={`${isMenuItemActive(item.to) ? classes.listItemActive : ''}`}
                            disabled={item.isComing}
                        >
                            <ListItemIcon className={classes.menuListIcon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />

                            {
                                item.beta &&
                                <Badge badgeContent={'BETA'} color="warning" className={classes.betaBadge}></Badge>
                            }
                        </ListItem>
                    ))
                }
            </List>
            <div className={classes.footer} style={{ textAlign: 'center', alignItems: 'center' }}>

                {user?.isBetaTester && (
                    <FormControlLabel
                        control={<Switch checked={darkMode} onChange={toggleDarkMode} />}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {darkMode ? <Brightness4 /> : <Brightness7 />}
                                {darkMode ? 'Modo Escuro' : 'Modo Claro'}
                                <Badge style={{ marginLeft: 30 }} badgeContent={'BETA'} color="warning" className={classes.betaBadge}></Badge>
                            </div>
                        }
                    />
                )}

                <Button
                    color='primary'
                    variant="outlined"
                    fullWidth
                    onClick={handleLogout}
                    style={{ marginBottom: 20, marginLeft: 5, marginTop: 20 }}
                >
                    Sair
                    <LogoutIcon color="secondary" className={classes.logoutIconButton} />
                </Button>
            </div>
        </Drawer>
    )
}

export default Menu
