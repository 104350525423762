/* eslint-disable import-helpers/order-imports */
import {
    Chart,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    TimeSeriesScale,
    Legend,
    Tooltip,
    TooltipModel,
    TooltipItem
} from 'chart.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
import { Bar } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'

import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import Map from '@/components/Map'
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    Skeleton
} from '@mui/material'

import useStyles from './styles'

import 'chartjs-adapter-dayjs'

// eslint-disable-next-line import-helpers/order-imports
import { useTheme } from '@mui/material/styles'
import { AuthContext } from '@/contexts/auth'
import { DateFilterContext } from '@/contexts/dateFilter'
import CampaignPlayer from '@/components/Player'

interface IAudience {
    timestamp: string;
    distributions: number;
}

type IListenersLocationResponse = {
    latitude: number
    longitude: number
    listeners: number
}[]

const CampaignMetrics: React.FC = () => {
    let { code, campaignUuid } = useParams()

    const theme = useTheme()

    const { setDarkMode } = useContext(AuthContext)
    const { dateFilter } = useContext(DateFilterContext)

    const [campaign, setCampaign] = useState<any>({})
    const [totalDistributed, setTotalDistributed] = useState<any>({})
    const [totalBonusDistributed, setTotalBonusDistributed] = useState<any>({})
    const [totalListeners, setTotalListeners] = useState<any>({})
    const [totalDistributedDayhours, setTotalDistributedDayhours] =
        useState<any>([])
    const [audioUrl, setAudioUrl] = useState<string>('')

    const [loadingCampaign, setLoadingCampaign] = useState<any>({})
    const [loadingTotalDistributed, setLoadingTotalDistributed] =
        useState<boolean>(true)
    const [loadingTotalBonusDistributed, setLoadingTotalBonusDistributed] =
        useState<boolean>(true)
    const [loadingTotalListeners, setLoadingTotalListeners] =
        useState<boolean>(true)
    const [
        loadingTotalDistributedDayhours,
        setLoadingTotalDistributedDayhours
    ] = useState<boolean>(true)
    const [loadingAdvertisementAudio, setLoadingAdvertisementAudio] =
        useState<boolean>(true)

    const [chartData, setChartData] = useState<any>([])
    const [coordinates, setCoordinates] = useState<IListenersLocationResponse>()
    const [loadingChartData, setLoadingChartData] = useState<boolean>(true)

    const classes = useStyles()

    const getCampaign = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}`
            )

            setCampaign(data)

            setLoadingCampaign(false)
        } catch (error) {}
    }

    const getCampaignCode = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/get-code/${campaignUuid}`
            )

            window.location.href = `/c/${data.code}`
        } catch (error) {}
    }

    const getTotalDistributed = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/total`
            )

            setTotalDistributed({
                quantity: data.quantity,
                quantityDistributed: data.quantityDistributed,
                percentage: data.percentage
            })

            setLoadingTotalDistributed(false)
        } catch (error) {}
    }

    const getTotalBonusDistributed = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/bonus/total`
            )

            setTotalBonusDistributed({
                quantity: data.quantity
            })

            setLoadingTotalBonusDistributed(false)
        } catch (error) {}
    }

    const getTotalListeners = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/listeners/total`
            )

            setTotalListeners({
                quantity: data.quantity
            })

            setLoadingTotalListeners(false)
        } catch (error) {}
    }

    const getAdvertisementAudio = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/advertisement/audio`
            )

            setAudioUrl(data.url)
            setLoadingAdvertisementAudio(false);
        } catch (error) {}
    }

    const getTotalDistributedDayhours = async () => {
        try {
            const { data }: any = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/dayhours`
            )

            const labels = data?.map((row: any) => row.hour)

            const datasets = [
                {
                    tension: 0.2,
                    fill: false,
                    borderColor: '#73bf69',
                    backgroundColor: '#73bf6980',
                    borderWidth: 1,
                    yAxisID: 'y',
                    data: data?.map((row: any) => +row.quantity)
                }
            ]

            setTotalDistributedDayhours({
                labels,
                datasets
            })

            setLoadingTotalDistributedDayhours(false)
        } catch (error) {}
    }

    useDidMount(() => {
        if (campaignUuid) {
            return getCampaignCode()
        }
        getCampaign()
        getTotalDistributed()
        getTotalBonusDistributed()
        getTotalListeners()
        getTotalDistributedDayhours()
        setDarkMode(false)
        getAdvertisementAudio()
    })

    Chart.register(
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        TimeSeriesScale,
        Legend,
        Tooltip
    )

    const getDistribuitionsData = async () => {
        setLoadingChartData(true)

        const { data } = await ApiService.get(
            '/metrics/campaigns/' + code + '/distribuitions'
        )

        const labels = data?.map((audience: IAudience) => audience.timestamp)

        const datasets = [
            {
                tension: 0.2,
                fill: false,
                borderColor: '#73bf69',
                backgroundColor: '#73bf6980',
                borderWidth: 1,
                yAxisID: 'y',
                data: data?.map(
                    (audience: IAudience) => +audience.distributions
                )
            }
        ]

        setChartData({
            labels,
            datasets
        })

        setLoadingChartData(false)
    }

    useEffect(() => {
        getDistribuitionsData()
        getMapLocations()
    }, [])

    const getMapLocations = async () => {
        try {
            const { data } = await ApiService.get<IListenersLocationResponse>(
                `/metrics/campaigns/${code}/listeners/location`
            )

            setCoordinates(data)
        } catch (error) {
            return []
        }
    }

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.mainContainer}
        >
            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingCampaign
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            {campaign.title ?? 'Campanha'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                        As métricas da sua campanha são
                                        atualizadas instantaneamente, permitindo
                                        que você acompanhe os resultados em
                                        tempo real.
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            {campaign.streamId && (
                <Card className={classes.infoCardContainer}>
                    <CardContent className={classes.cardContent}>
                        <Grid container gap={1} justifyContent="space-around">
                            <Grid item xs={12}>
                                <Typography className={classes.titleText}>
                                    <b
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Ouça a propaganda
                                    </b>
                                </Typography>
                            </Grid>
                            {loadingAdvertisementAudio
                                ? (
                                    <CircularProgress size={40} color="primary" />
                                ) : (
                                    <Grid sx={{ backgroundColor: '#cfcfcf' }} item xs={12} borderRadius={2}>
                                        <CampaignPlayer url={audioUrl} />
                                    </Grid>
                                )}
                        </Grid>
                    </CardContent>
                </Card>
            )}

            <Card className={classes.infoCardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingTotalDistributed
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b
                                                style={{
                                                    fontSize: '30px',
                                                    fontWeight: 1000
                                                }}
                                            >
                                                {
                                                    totalDistributed.quantityDistributed > totalDistributed.quantity? totalDistributed.quantity : totalDistributed.quantityDistributed
                                                }{' '}
                                            / {totalDistributed.quantity}
                                            </b>
                                            <b
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: 100,
                                                    paddingLeft: '5px'
                                                }}
                                            >
                                            veiculações
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div
                                            className={classes.progressContainer}
                                            style={{
                                                background: `linear-gradient(90deg, #73BF69 ${totalDistributed.percentage}%, #ccccdc30 ${totalDistributed.percentage}%`
                                            }}
                                        ></div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                        Total de propagandas veiculadas
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.infoCardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingTotalListeners
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b
                                                style={{
                                                    fontSize: '30px',
                                                    fontWeight: 1000
                                                }}
                                            >
                                                {totalListeners.quantity}
                                            </b>
                                            <b
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: 100,
                                                    paddingLeft: '5px'
                                                }}
                                            >
                                            ouvintes alcançados
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                        Total de ouvintes alcançados
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.cardContainerDistribution}>
                <CardContent className={classes.cardContent}>
                    <Grid container className={classes.chartGrid}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="space-around">
                                <Typography
                                    className={classes.titleTextDistribution}
                                >
                                    Comerciais veiculados
                                </Typography>
                            </Grid>
                        </Grid>
                        {loadingChartData
                            ? (
                                <Grid item xs={12} sm={12}>
                                    {_.times(28, (i: any) => (
                                        <Skeleton
                                            variant="rectangular"
                                            width="2.5%"
                                            height={(i + 1) * 11}
                                            style={{
                                                float: 'left',
                                                marginLeft: '1%',
                                                marginTop: 360 - (i + 1) * 11
                                            }}
                                        />
                                    ))}
                                </Grid>
                            )
                            : (
                                <Grid item xs={12} sm={12}>
                                    {chartData && chartData?.datasets && (
                                        <Bar
                                            height={80}
                                            data={chartData}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        backgroundColor: '#202227',
                                                        titleColor: '#ccccdc',
                                                        boxHeight: 1,
                                                        boxPadding: 3,
                                                        bodySpacing: 5,
                                                        callbacks: {
                                                            label: function (
                                                                this: TooltipModel<'bar'>,
                                                                tooltipItem: TooltipItem<'bar'>
                                                            ) {
                                                                return (
                                                                    tooltipItem.formattedValue +
                                                                ' veiculações'
                                                                )
                                                            },
                                                            title: function (
                                                                this: TooltipModel<'bar'>,
                                                                tooltipItem: TooltipItem<'bar'>[]
                                                            ) {
                                                                return dayjs(
                                                                    tooltipItem[0]
                                                                        .label
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                )
                                                            }
                                                        }
                                                    }
                                                },
                                                responsive: true,
                                                interaction: {
                                                    mode: 'index',
                                                    intersect: false
                                                },
                                                elements: {
                                                    point: {
                                                        radius: 0
                                                    }
                                                },
                                                scales: {
                                                    x: {
                                                        type: 'time',
                                                        time: {
                                                            unit: 'day',
                                                            displayFormats: {
                                                                day: 'DD/MM'
                                                            }
                                                        },
                                                        grid: {
                                                            color: theme.palette
                                                                .info[
                                                                    theme.palette.mode
                                                                ],
                                                            borderColor:
                                                            theme.palette.info[
                                                                theme.palette
                                                                    .mode
                                                            ]
                                                        },
                                                        ticks: {
                                                            color: theme.palette
                                                                .primary[
                                                                    theme.palette.mode
                                                                ]
                                                        }
                                                    },
                                                    y: {
                                                        type: 'linear',
                                                        display: true,
                                                        position: 'left',
                                                        grid: {
                                                            color: theme.palette
                                                                .info[
                                                                    theme.palette.mode
                                                                ],
                                                            borderColor:
                                                            theme.palette.info[
                                                                theme.palette
                                                                    .mode
                                                            ]
                                                        },
                                                        ticks: {
                                                            color: theme.palette
                                                                .primary[
                                                                    theme.palette.mode
                                                                ]
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                </Grid>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.cardContainerDistribution} style={{ marginBottom: 15 }}>
                <CardContent className={classes.cardContent}>
                    <Grid container className={classes.chartGrid}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="space-around">
                                <Typography
                                    className={classes.titleTextDistribution}
                                >
                                    Ouvintes por cidade
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.mapsContainer}
                        >
                            <Map coordinates={coordinates} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* <Card className={classes.infoCardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {
                            loadingTotalBonusDistributed ?
                                <CircularProgress size={80} color="primary" /> :
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b style={{ fontSize: "30px", fontWeight: 1000 }}>{totalBonusDistributed.quantity}</b>
                                            <b style={{ fontSize: "20px", fontWeight: 100, paddingLeft: '5px' }}>veiculações bônus</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.descriptionText}>
                                            Essas são veiculações que nós distribuimos de forma gratuita para atingir um numero maior de ouvintes.
                                        </Typography>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </CardContent>
            </Card> */}

            {/* <Card className={classes.infoCardContainer} style={{ marginBottom: "60px" }}>
                <CardContent className={classes.cardContent}>
                    <Grid item xs={12}>
                        {
                            loadingTotalDistributedDayhours ?
                                <CircularProgress size={80} color="primary" /> :
                                <>
                                    {
                                        totalDistributedDayhours &&
                                        totalDistributedDayhours?.datasets &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography className={classes.titleText}>
                                                    <b style={{ fontSize: "30px", fontWeight: 1000 }}>HORÁRIOS VEICULADOS</b>
                                                </Typography>
                                            </Grid>
                                            <Bar
                                                height={120}
                                                data={totalDistributedDayhours}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false
                                                        },
                                                        tooltip: {
                                                            backgroundColor: '#202227',
                                                            titleColor: '#ccccdc',
                                                            boxHeight: 1,
                                                            boxPadding: 3,
                                                            bodySpacing: 5,
                                                            callbacks: {
                                                                label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
                                                                    // console.log(tooltipItem)
                                                                    return tooltipItem.formattedValue + ' ouvintes'
                                                                }
                                                            }
                                                        }
                                                    },
                                                    responsive: true,
                                                    interaction: {
                                                        mode: 'index',
                                                        intersect: false
                                                    },
                                                    elements: {
                                                        point: {
                                                            radius: 0
                                                        }
                                                    },
                                                    scales: {
                                                        x: {
                                                            type: 'category',
                                                            grid: {
                                                                color: '#272a2e',
                                                                borderColor: '#272a2e'
                                                            },
                                                            ticks: {
                                                                color: '#ccccdc'
                                                            }
                                                        },
                                                        y: {
                                                            type: 'linear',
                                                            display: true,
                                                            position: 'left',
                                                            grid: {
                                                                color: '#272a2e',
                                                                borderColor: '#272a2e'
                                                            },
                                                            ticks: {
                                                                color: '#ccccdc'
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </>
                                    }
                                </>
                        }
                    </Grid>
                </CardContent>
            </Card> */}
        </Grid>
    )
}

export default CampaignMetrics
